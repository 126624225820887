/*custom button css*/

.loaderSection{
    display: flex;
    align-items: center;
}
.loaderSectionText{
    font-size: 10px;
    margin-right: 5px;
    text-transform: capitalize;
}