.textfieldclass input {
    height: 14px !important;
    padding: 14px 10px;
}

.textfieldclass .MuiOutlinedInput-root {
    position: relative;
    border-radius: 8px;
    
}

.phoneerror {
    margin: 0!important;
    color: red;
    font-size: 12px!important;
  }
  .inputmaskerror {
    border-color: red!important;
  }
  .textfieldinput .MuiFormHelperText-contained{
    margin-left: 0px !important;
  }