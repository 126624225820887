.card {
    border: 1px solid rgba(187, 187, 187, 0.3);
}

.cardSelected {
    border: 1px solid #E47B12;
    box-shadow: 0px 2px 0px #E47911;
}

.bottomBorder {
    border-bottom: 1px solid rgba(187, 187, 187, 0.3);
}

.gridContainer {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
}

.ListRoot:hover {
    background: linear-gradient(90deg, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%);
}

.netSupportShadow {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
}

.gnExtendBorderRadius {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.contain{
    width: 90px;
    height: 85px;
}