.TabComponent {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    background: #E0E0E0;
    
}

.tabs {
    font-family: "IBM Plex Sans", sans-serif;
    color: #4F4F4F;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: bold;
    padding: 9px 18px;
    margin: 6px 2px;
    border: none;
    display: flex;
    -webkit-box-pack: start;
    justify-content: center;
    align-items: center;
    background-color: #E0E0E0;
    border-radius: 999em;
    -webkit-border-radius: 999em;
    -moz-border-radius: 999em;
    -ms-border-radius: 999em;
    -o-border-radius: 999em;
}

.selectedTab {
    background-color: #1A1A1A;
    color: #ffffff;
    box-shadow: rgb(208 215 222) 0px 4px 8px;
}
