.header-container {
    width: 100%;
    background-color: #F5F5F5;
    min-height: fit-content;
    border-bottom: 1px solid #E4E4E4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 10;
}

.header-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    padding: 1rem;
}

.header-img {
    cursor: pointer;
}

.disable-img {
    cursor: not-allowed;
}

.header-mid {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
}

.header-mid-text{
    font-size: 20px;
    color: #1473E6;
    font-weight: 500;
}

.header-right {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin-right: 1.5rem;
}

.cancelBtncls.MuiButton-root{
    border: 1.5px solid #66666666!important;
}