.sideNav-container {
    width: 6rem;
    max-width: 10rem;
    background-color: #e4e4e4;
    height: auto;
    border-right: 1px solid #E4E4E4;
    padding-top: 1rem;
}

.rightsideNav-container {
    /* width: 8rem; */
    /* max-width: 10rem; */
    background-color: #f6f6f6;
    height: 100%;
    border-right: 1px solid #E4E4E4;
    padding-top: 1rem;
    /* float: right; */
}

.sideNav-item {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: flex-start;
    justify-content: center;
    padding: 0px .5rem;
}

.rightsideNav-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    justify-content: center;
    /* padding: 0px .5rem; */
}

.item {
    /* min-width: 4rem; */
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 5px;
    /* background-color: #FFFFFF; */
    /* border: 1px solid #E0E0E0; */
    /* padding: 2rem 2rem; */
    /* border-radius: 8px; */
    cursor: pointer;
}

.lastItem {
    /* min-width: 4rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* background-color: #FFFFFF;
    border: 1px solid #E0E0E0; */
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    /* border-radius: 8px; */
    cursor: pointer;
}

.first-pannel {
    min-width: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* background-color: #FFFFFF; */
    /* border: 1px solid #E0E0E0; */
    padding: 0.5rem .5rem;
    /* border-radius: 8px; */
    cursor: pointer;
}

.item-img img {
    object-fit: contain;
}

p.item-title {
    margin: 0;
}

.item-title {
    text-align: center;
    font-size: 14px;
    color: #454545;
}

.dialogBoxWidth div>div {
    max-width: 40rem;
}