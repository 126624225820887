.afterEffect a::after {
    /* content: "| "; */
    padding: 0px 10px 0px 15px;
}

/* .afterEffect a:nth-child(6):after {
    content: " ";
} */

.underline {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .underline::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 2px;
    /* top: 3.5em; */
    /* background-color: #FF5C00; */
    background-color: #0E3052;
    transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  }
  
  @media (hover: hover) and (pointer: fine) {
    .underline:hover::before {
      left: 0;
      right: auto;
      width: 100%;
    }
  }

  .avtarBoxShadow {
  -webkit-box-shadow: 0px 0px 8px -2px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 8px -2px rgba(0,0,0,0.75);
box-shadow: 0px 0px 8px -2px rgba(0,0,0,0.75);
}