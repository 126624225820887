/* .outer {
    position: relative;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    border: 1px solid #ff5c00;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.selectedPart {
    /* position: relative;
    top: 4px;
    left: 4px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #45C0FE;
    color: white;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 12px;
}

.selectedLinePart {
    /* background: linear-gradient(to right, #FF5C00 50%, #d8d8d8 50%); */
    height: 1px;
    width: 7rem;
}

@media only screen and (max-width: 768px) {
    .selectedLinePart {
        width: 3rem;
    }
}


/* @media only screen and (max-width: 360px) and (min-width: 300px){
    .selectedLinePart {
        width: 1.5rem;
    }
    .smallerLine {
        width: 1.5rem;
    }
} */