.App {
  font-family: sans-serif;
  text-align: center;
}

.mainSection {
  display: flex;
  justify-content: space-between;
  margin-top: 59px;
}

.mainSection-sidebar {
  display: flex;
}

.mainSection-editorSection {
  flex: 2;
}

.sample-canvas {
  height: 87vh;
  /* For 84% screen height */
  width: 70vw;
  /* For 65% screen width */
}
.assemmbly-canvas {
  height: 90vh;
  /* For 84% screen height */
  width: 78vw;
}

.roundSolarNet1:nth-child(2) {
  border-top-right-radius: 8px;
}

.roundSolarNet2:nth-child(2) {
  border-bottom-right-radius: 8px;
}

.roundedSupport:nth-child(3) {
  border-top-right-radius: 8px;
}

.roundedPanel:nth-child(1) {
  border-bottom-right-radius: 8px;
}