.labelcs{
    letter-spacing: 0.005em;
    display: flex;
    margin-bottom: 1em;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #666666;
    border-radius: 24px;
}
