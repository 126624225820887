/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#canvas .headerBox {
  font-family: "IBM Plex Sans", sans-serif;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.bottom-buttons {
  bottom: 20px;
  left: 20px;
}

.header_section {
  background-color: #f0f0f0;
  height: fit-content;
  display: flex;
}

.header_icon_section {
  display: flex;
  width: 50%;
  gap: 2.5rem;
  padding: 15px 0px 15px 15px;
  cursor: pointer;
}

.header_button_section {
  display: flex;
}

.bottom-buttons a:hover {
  color: #555;
}

.bottom-buttons a.active {
  opacity: 1;
  box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.1);
}

/* screen interaction helpers & modal */

#io-dialog-main {
  display: none;
  /*default*/
}

#io-editing-tools-buttons {
  display: block;
  position: fixed;
  top: 0px;
  right: 80px;
  list-style: none;
  padding: 5px;
  margin: 0;
}

#io-editing-tools-buttons button:hover {
  color: #333333;
}

.icon-keyboard {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 18px;
  line-height: 1.2em;
}

.icon-keyboard::before {
  content: "\f11c";
  display: inline-block;
  line-height: 1.2em;
}

.icon-resize-full {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 18px;
  line-height: 1.2em;
}

.icon-resize-full::before {
  content: "\f31e";
  display: inline-block;
}

.keybindings-dialog .binding {
  padding: 5px 10px;
  font-family: monospace;
}

/*404 page */
.man404 {
  width: 100%;
  height: 100vh;
}

.man404Img {
  width: 100%;
  height: 100%;
}

.man404Text {
  text-transform: capitalize;
  position: absolute;
  bottom: 35%;
  left: 15%;
}

.man404TextOOPS {
  font-size: 130px;
  font-weight: 300;
  font-family: system-ui;
  color: rgb(63, 63, 63);
}

.man404TextNot {
  font-size: 40px;
  font-family: system-ui;
  font-weight: 300;
  color: rgb(123, 123, 123);
}

/*404 page styles end*/

.textfieldactive.textfieldinput.phonefieldInput label {
  padding-bottom: 8px;
  color: #666666;
  font-size: 16px;
  /* font-family: "Ubuntu"; */
}

.textfieldactive.textfieldinput.phonefieldInput input {
  padding: 9.5px 10px !important;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  width: 30em;
  outline-color: #ff5c00;
}

@media only screen and (max-width: 640px) {
  .textfieldactive.textfieldinput.phonefieldInput input {
    padding: 9.5px 10px !important;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    width: 16em;
    outline-color: #ff5c00;
  }
}

/*this style section is for phone field*/
.textfieddeactv.textfieldinput.phonefieldInput label.MuiFormLabel-root.MuiInputLabel-root.textinputlabel {
  padding-bottom: 8px;
  color: #666666;
  font-size: 16px;
  /* font-family: "Ubuntu"; */
}

.textfieddeactv.textfieldinput.phonefieldInput input {
  padding: 9.5px 10px !important;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  width: 30em;
}

@media only screen and (max-width: 640px) {
  .textfieddeactv.textfieldinput.phonefieldInput input {
    padding: 9.5px 10px !important;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    width: 16em;
  }
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  /* border-color: #ff5c00 !important; */
  border-color: #333333 !important;
  border: 1px solid !important;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4f4f4f;
}

/* import font styles */

@font-face {
  font-family: "SansBold";
  src: url("../src/fonts/productSans/Product\ Sans\ Bold.ttf");
}

@font-face {
  font-family: "SansRegular";
  src: url("../src/fonts/productSans/Product\ Sans\ Regular.ttf");
}

@font-face {
  font-family: "SansBlack";
  src: url("../src/fonts/productSans/FontsFree-Net-ProductSans-Black.ttf");
}

@font-face {
  font-family: "RobotoRegular";
  src: url("../src/fonts/Roboto/RobotoCondensed-Regular.ttf");
}

@font-face {
  font-family: "RubikRegular";
  src: url("../src/fonts/Rubik/static/Rubik-Regular.ttf");
}

@font-face {
  font-family: "RobotoRegular2nd";
  src: url("../src/fonts/Roboto2nd/Roboto-Regular.ttf");
}

@font-face {
  /*100*/
  font-family: "InterThin";
  src: url("../src/fonts/Inter/Inter-Thin.ttf");
}
@font-face {
  /*200*/
  font-family: "InterExtraLight";
  src: url("../src/fonts/Inter/Inter-ExtraLight.ttf");
}
@font-face {
  /*300*/
  font-family: "InterLight";
  src: url("../src/fonts/Inter/Inter-Light.ttf");
}
@font-face {
  /*400*/
  font-family: "InterRegular";
  src: url("../src/fonts/Inter/Inter-Regular.ttf");
}
@font-face {
  /*500*/
  font-family: "InterMedium";
  src: url("../src/fonts/Inter/Inter-Medium.ttf");
}
@font-face {
  /*600*/
  font-family: "InterSemiBold";
  src: url("../src/fonts/Inter/Inter-SemiBold.ttf");
}
@font-face {
  /*700*/
  font-family: "InterBold";
  src: url("../src/fonts/Inter/Inter-Bold.ttf");
}
@font-face {
  /*800*/
  font-family: "InterExtraBold";
  src: url("../src/fonts/Inter/Inter-ExtraBold.ttf");
}
@font-face {
  /*900*/
  font-family: "InterBlack";
  src: url("../src/fonts/Inter/Inter-Black.ttf");
}

@font-face {
  font-family: "RobotoMedium";
  src: url("../src/fonts/Roboto/RobotoCondensed-Regular.ttf");
}

@font-face {
  font-family: "RobotoSemiBold";
  src: url("../src/fonts/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "RobotoBold";
  src: url("../src/fonts/Roboto/RobotoCondensed-Bold.ttf");
}


.autoField {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.05);
  background: rgba(237, 240, 240, 0.2);
}

.autoField input:focus {
  outline: none;
}

.autoField div:nth-child(2) {
  border: none;
  border-radius: 8px;
  background-color: #ffffff00;
}

.confirmationModalWidth .MuiDialog-paper {
  width: 30rem;
}

.quoteDetailsModal .MuiDialog-paper {
  /* width: 680px !important; */
  max-width: fit-content !important;
}

.ConfirmMagicLinkModal .MuiDialog-paper {
  width: 30rem;
}

.magicCancelBtn .MuiButton-root {
  border: 1px solid #c4c4c4;
  border-radius: 50px;
}

@media print {

  html,
  body {
    height: initial !important;
    overflow: initial !important;
  }
}

/* Default Search Result */
.searchResult_Panel {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  column-gap: 1rem;
}

/* Rounded Input */
.roundInput .MuiOutlinedInput-root {
  border-radius: 50px !important;
}

/* driver.js popover style*/

/* #driver-popover-content:has(iframe){
  max-width: 50% !important;
  width: 50%;
  height: fit-content;
  position: fixed !important;
  top: 50%;
  left: 50%;
} */
.panelSearchModal .MuiDialog-paper {
  min-width: 40rem;
}

/* for driver js */
.video_popover {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  min-width: 700px !important;
  max-width: 800px !important;
  height: 500px !important;
}

.video_popover .driver-popover-description {
  height: 80% !important;
  /* margin-bottom: 10px; */
}
/* driver js css end */

/* React player classes  */
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  max-width: none !important;
}

.helpvideomodal div p {
   display: flex ;
   align-items: start;
   justify-content: start;
   max-height: none !important ;
}

.helpvideomodal div p video, .helpvideomodal div p,  .helpvideomodal div, .helpvideomodal{
  /* max-width: 600px !important; */
  max-height: 80vh;
  width: fit-content !important;
  height: auto !important;
  border-radius: 16px;
}


.bootstrap .MuiPaper-root {
    background-color: transparent !important;
    box-shadow: none !important;
    position: block !important;
    max-width: none !important;
}
.bootstrap .MuiButtonBase-root  {
  background-color: white;
}

.bootstrap .MuiBackdrop-root{
  background-color:rgba(0, 0, 0, 0.2) !important ;
}
.video_help_modal p {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}